<template>
  <div class="services-header">
    <div class="services-header__back">
      <v-btn fab dark color="info" x-small text @click="$router.go(-1)">
        <IconPictoAarrowsLeft />
      </v-btn>
    </div>
    <div class="services-header__title">{{ category.name }}</div>
    <div class="services-header__description">
      {{ category.description }}
    </div>
    <div class="services-header__search">
      <v-text-field
        v-model.trim="searchField"
        outlined
        type="text"
        persistent-placeholder
        dense
        placeholder="Rechercher un service"
      >
        <template v-slot:append>
          <v-icon v-if="!loading" color="primary">mdi-magnify</v-icon>
          <v-progress-circular
            v-else
            :size="23"
            :width="2"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/utils/index.js";
export default {
  name: "ServicesHeader",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconPictoAarrowsLeft: () => import("~cp/Icons/IconPictoAarrowsLeft"),
  },
  data() {
    return {
      searchField: "",
    };
  },
  watch: {
    searchField: {
      handler: debounce(function (newVal) {
        this.$emit("debouncedInput", newVal);
      }, 500),
    },
  },
};
</script>

<style src="./ServicesHeader.scss" lang="scss" scoped></style>